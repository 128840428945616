const ytRegex =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
const vimeoRegex =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
const soundcloudRegex = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/

export default function mediaPlayerLink(link, excludeSoundCloud = false) {
  if (
    ytRegex.test(link) ||
    vimeoRegex.test(link) ||
    (!excludeSoundCloud && soundcloudRegex.test(link))
  ) {
    return link
  }

  return null
}
