import classnames from 'classnames'
import React from 'react'
import Icon from '../../../common/Icon'

const UploadButton = ({
  htmlFor,
  onChange,
  disabled,
  label,
  iconName,
  accept,
  marginTop,
  className,
  isActive,
  onClick,
}) => {
  const style = classnames(
    'px-4 py-3 rounded-xl max-w-200 font-semibold text-black flex-row flex items-center cursor-pointer bg-alt-bg hover:bg-gray-200',
    {
      'cursor-not-allowed opacity-50': disabled,
    },
    {
      'mt-4': marginTop,
    },
    {
      'bg-topic-green text-white': isActive,
    },
    className
  )

  return (
    <div>
      <label htmlFor={htmlFor} className={style}>
        <Icon name={iconName} size={24} color={isActive ? 'white' : 'black'} />
        {label}
      </label>
      <input
        type="file"
        id={htmlFor}
        className="hidden"
        onChange={onChange}
        accept={accept}
        disabled={disabled}
        onClick={onClick ? () => onClick() : null}
      />
    </div>
  )
}

export default UploadButton
