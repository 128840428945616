import React, { useState } from 'react'
import TextInput from '../../common/TextInput'

const AltTextInput = ({register, showText = false}) => {
  const [showAltTextInput, setShowAltTextInput] = useState(showText)

  if (!showAltTextInput) {
    return (
      <div>
        <span
          onClick={() => setShowAltTextInput(true)}
          onKeyDown={() => setShowAltTextInput(true)}
          role="button"
          tabIndex="0"
          className="text-base text-blue-500 font-bold"
        >
          Add Alt Text
        </span>
        <p className="text-sm text-subtitle">
          Alt text describes the appearance or function of an image to improve
          accessibility for visually impaired members.
        </p>
      </div>
    )
  }

  return <TextInput name="altText" label="Add alt text" register={register} />
}

export default AltTextInput
