import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { Dashboard } from '@uppy/react'
import Tus from '@uppy/tus'
import React, { useEffect, useState } from 'react'
import links from '../../../../config/links'
import Button from '../../../common/Button'
import Icon from '../../../common/Icon'

const UppyUploadButton = ({
  allowVideoUpload,
  setVideoUppy,
  setVideoId,
  getValues,
  handleSetResourceOption,
}) => {
  const configureUppy = () => {
    const uppy = new Uppy({
      restrictions: {
        maxFileSize: 2147483648, // 2GB limit
        maxNumberOfFiles: 1,
        allowedFileTypes: ['video/*'],
      },
      autoProceed: true,
      doneButtonHandler: null,
    })

    uppy.use(Tus, {
      endpoint: `/api/get-upload-url`,
      resume: true,
      removeFingerprintOnSuccess: true,
    })

    uppy.on('complete', (result) => {
      const videoId = result.successful[0].uploadURL
        .split('/')
        .pop()
        .split('?')[0]
      setVideoId(videoId)
    })

    setVideoUppy(uppy)
    return uppy
  }

  useEffect(() => {
    if (!allowVideoUpload) {
      return
    }

    const uppy = configureUppy()

    return () => uppy.close()
  }, [])

  return (
    <Button
      noPadding
      primary={getValues('isVideo')}
      className="px-4 py-3 font-semibold rounded-xl hover:bg-gray-200"
      onClick={() => {
        if (!getValues('isVideo')) {
          handleSetResourceOption('isVideo')
          configureUppy()
        } else {
          handleSetResourceOption()
          setVideoId(null)
        }
      }}
      disabled={!allowVideoUpload}
    >
      <div className="flex-row flex items-center">
        <Icon
          name="play"
          size={24}
          color={getValues('isVideo') ? 'white' : 'black'}
        />
        Video
      </div>
    </Button>
  )
}

export const UppyDashboard = ({ videoUppy, videoId }) => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 768
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  const dashboardHeight = windowWidth < 768 ? 300 : 200

  return (
    <div className="flex flex-col mt-3 w-full max-w-144">
      <p className="mb-2 font-bold text-black">Upload Video</p>
      <Dashboard
        uppy={videoUppy}
        proudlyDisplayPoweredByUppy={false}
        width="100%"
        height={dashboardHeight}
        note="Video must be less than 2GB"
        doneButtonHandler={() => null}
        theme={'light'}
      />
      <p className="text-gray-400 text-sm mt-2">
        Keep this window open, if your file is taking too long to upload{' '}
        <a href={links.videoCompressionGuide} className="text-topic-blue">
          try compressing it.
        </a>
      </p>
      {videoId !== null && (
        <div className="text-gray-400 mt-1 text-sm">
          Video successfully uploaded
        </div>
      )}
    </div>
  )
}

export const UppyEnableMessage = ({ allowVideoUpload, videoId }) => {
  if (allowVideoUpload || videoId) {
    return null
  }

  return (
    <p className="text-gray-400 mt-1">
      Video is a premium feature,{' '}
      <a className="text-topic-blue" href={links.supportEmail}>
        enquire about enabling
      </a>
    </p>
  )
}

export default UppyUploadButton
