import Button from '../Button'
import BaseModal from './BaseModal'

export default function ConfirmModal({
  isOpen,
  onRequestClose,
  action,
  actionLabel = 'Confirm',
  cancelLabel = 'Cancel',
  danger = false,
  title,
  subtitle,
}) {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} closeIcon>
      <div className="w-full md:min-w-600 flex flex-col px-2 text-center">
        {title && <h1 className="font-bold mb-5">{title}</h1>}
        {subtitle && <p className="mb-5">{subtitle}</p>}
        <div className="flex flex-row justify-end mt-5">
          <Button
            noPadding
            className="mr-2 px-5 py-2 text-off-black"
            onClick={onRequestClose}
          >
            {cancelLabel}
          </Button>
          <Button
            noPadding
            className="mr-2 px-5 py-2 text-alert"
            alert={danger}
            outline
            onClick={action}
          >
            {actionLabel}
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}
