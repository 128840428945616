import React from 'react'
import FilePreview from '../FilePreview'
import UploadButton from './UploadButton'

const FileUpload = ({
  getValues,
  file,
  handleSetFile,
  setFile,
  selectImage,
}) => (
  <>
    <div className="w-full flex flex-col sm:flex-row justify-start gap-2 mt-4">
      <UploadButton
        htmlFor="upload-file-button"
        label="Add your File"
        iconName="upload"
        onChange={handleSetFile}
        disabled={!getValues('isFile')}
      />
      <UploadButton
        htmlFor="upload-other-image-button"
        label="Add your Image"
        iconName="image"
        onChange={selectImage}
        accept="image/*"
      />
    </div>
    {file && (
      <FilePreview
        preview={file?.preview}
        filename={file?.raw?.name || 'File attachment'}
        type="File"
        onClick={() => setFile(null)}
      />
    )}
  </>
)

export default FileUpload
