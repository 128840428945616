import Icon from '../../common/Icon'

const FilePreview = ({
  type,
  onClick,
  filename,
  preview,
  label = 'Attachment',
  hideLabel,
}) => (
  <div className="flex flex-col my-5">
    {!hideLabel && <span className="font-bold mb-5">{label}</span>}
    <div className="flex flex-col sm:flex-row items-start sm:items-center">
      {type === 'image' && (
        <img
          src={preview}
          alt="Upload"
          width="300"
          height="200"
          className="rounded object-cover mb-3 w-48 h-20 mr-3"
        />
      )}
      <div className="text-offBlack">
        <button onClick={onClick}>
          {filename}
          <Icon name="times" color="alert" size={18} />
        </button>
      </div>
    </div>
  </div>
)

export default FilePreview
