import React, { useState } from 'react'
import handleSetImage from '../../../../lib/handleSetImage'
import validFileSize from '../../../../lib/validFileSize'
import BasicErrorMessage from '../../../common/BasicErrorMessage'
import FileUpload from './FileUpload'
import ImagePreview from './ImagePreview'
import LinkInput from './LinkInput'
import SubgroupButton from './SubgroupButton'
import UploadButton from './UploadButton'
import UppyUploadButton, {
  UppyDashboard,
  UppyEnableMessage,
} from './UppyUploadButton'

const ResourceRow = ({
  register,
  setImage,
  image,
  setFile,
  file,
  errors,
  setValue,
  getValues,
  videoId,
  setVideoId,
  allowVideoUpload,
}) => {
  const [fileError, setFileError] = useState(null)
  const [videoUppy, setVideoUppy] = useState(null)

  const handleSetFile = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0]
      if (validFileSize(file, setFileError)) {
        setFile({
          preview: URL.createObjectURL(file),
          raw: file,
        })
      }
    } else {
      setFile(null)
    }
  }

  const selectImage = (e) => {
    handleSetImage(e, setImage, setFileError)
  }

  const handleSetResourceOption = (key) => {
    const defaultValues = {
      isImage: false,
      isFile: false,
      isLink: false,
      link: null,
      altText: null,
      isVideo: false,
    }

    Object.keys(defaultValues).forEach((field) => {
      setValue(field, defaultValues[field])
    })

    if (key) {
      setValue(key, true)
    }
  }

  const handleClearResource = () => {
    setImage(null)
    setFile(null)
    setVideoId(null)
  }

  return (
    <div className="flex flex-col mt-5">
      <div className="flex flex-col justify-start">
        <div className="flex flex-row flex-wrap justify-start items-start gap-2">
          <UploadButton
            htmlFor="upload-image-button"
            label="Image"
            iconName="image"
            isActive={getValues('isImage')}
            onClick={() => {
              handleClearResource()

              if (!getValues('isImage')) {
                handleSetResourceOption('isImage')
              }
            }}
            onChange={(e) => {
              selectImage(e)
            }}
            accept="image/*"
          />
          <SubgroupButton
            isActive={getValues('isFile')}
            iconName="attachment"
            label="File"
            onClick={() => {
              handleClearResource()

              if (!getValues('isFile')) {
                handleSetResourceOption('isFile')
              } else {
                handleSetResourceOption()
              }
            }}
          />
          <SubgroupButton
            isActive={getValues('isLink')}
            iconName="link"
            label="Link"
            onClick={() => {
              handleClearResource()

              if (!getValues('isLink')) {
                handleSetResourceOption('isLink')
              } else {
                handleSetResourceOption()
              }
            }}
          />
          <UppyUploadButton
            allowVideoUpload={allowVideoUpload}
            setVideoUppy={setVideoUppy}
            setVideoId={setVideoId}
            getValues={getValues}
            handleSetResourceOption={handleSetResourceOption}
          />
        </div>
        <UppyEnableMessage
          allowVideoUpload={allowVideoUpload}
          videoId={videoId}
        />
        {getValues('isLink') && (
          <LinkInput
            errors={errors}
            register={register}
            selectImage={selectImage}
          />
        )}
        <BasicErrorMessage error={fileError} />
        {getValues('isFile') && (
          <FileUpload
            getValues={getValues}
            file={file}
            handleSetFile={handleSetFile}
            setFile={setFile}
            selectImage={selectImage}
          />
        )}
        {image && (
          <ImagePreview
            image={image}
            setValue={setValue}
            setImage={setImage}
            register={register}
            getValues={getValues}
          />
        )}
        {getValues('isVideo') && (
          <UppyDashboard videoUppy={videoUppy} videoId={videoId} />
        )}
        <BasicErrorMessage error={fileError} />
      </div>
    </div>
  )
}

export default ResourceRow
