import React from 'react'
import Button from '../../../common/Button'
import Icon from '../../../common/Icon'

const SubgroupButton = ({ isActive, onClick, iconName, label }) => (
  <Button
    noPadding
    primary={isActive}
    className="px-4 py-3 font-semibold rounded-xl hover:bg-gray-200"
    onClick={onClick}
  >
    <div className="flex-row flex items-center">
      <Icon name={iconName} size={24} color={isActive ? 'white' : 'black'} />
      {label}
    </div>
  </Button>
)

export default SubgroupButton
