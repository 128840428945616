import React from 'react'
import AltTextInput from '../AltTextInput'
import FilePreview from '../FilePreview'

const ImagePreview = ({ image, setValue, setImage, register, getValues }) => (
  <>
    <FilePreview
      preview={image.preview || image}
      filename={image?.raw?.name || 'Image'}
      type="image"
      onClick={() => {
        if (setValue) {
          setValue('altText', null, { shouldValidate: true })
        }
        setImage(null)
      }}
    />
    <AltTextInput
      register={register}
      showText={() => {
        if (getValues) {
          return getValues('altText')
        }
        return false
      }}
    />
  </>
)

export default ImagePreview
