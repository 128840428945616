import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import Icon from '../Icon'
import PopoutButton from './PopoutButton'

const CopyButton = ({
  copyable,
  label = 'Copy link',
  copiedLabel = 'Copied',
  isPopout = true,
}) => {
  const [copied, setCopied] = useState(false)

  function copyToClipboard(text) {
    copy(text)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  if (isPopout) {
    return (
      <PopoutButton onClick={() => copyToClipboard(copyable)}>
        {copied ? copiedLabel : label}
      </PopoutButton>
    )
  }

  return (
    <div className="flex flex-col">
      <span className="font-bold mb-2">{label}</span>
      <button
        className="bg-alt-bg rounded-md placeholder-subtitle border-0 focus:outline-none focus:ring-1 focus:ring-subtitle flex flex-row cursor-pointer h-full px-4 py-3 "
        onClick={() => copyToClipboard(copyable)}
      >
        <b className="overflow-hidden w-full text-left text-gray-700">
          {copyable}
        </b>

        <Icon name="link" size={22} className="m-auto" />
      </button>
      <span className="ml-4 mt-2 text-subtitle italic">
        {copied ? 'Copied to clipboard' : 'Click to copy to your clip board'}
      </span>
    </div>
  )
}

export default CopyButton
