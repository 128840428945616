import React from 'react'
import TextInput from '../../../common/TextInput'
import UploadButton from './UploadButton'

const LinkInput = ({ errors, register, selectImage }) => (
  <div className="w-full flex flex-col sm:flex-row justify-between sm:items-center gap-2 mt-4">
    <TextInput
      name="link"
      label="Add a link"
      required
      hint={errors?.link?.message}
      register={register}
      className="w-full sm:w-7/12"
    />
    <UploadButton
      htmlFor="upload-other-image-button"
      label="Add your Image"
      iconName="image"
      onChange={selectImage}
      accept="image/*"
      marginTop
    />
  </div>
)

export default LinkInput
